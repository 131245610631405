<template>
    <div v-prefix class="#image">
        <div v-prefix class="#image__gallery-header-container border-light-b">
            <gallery-header :gallery="gallery" />
        </div>

        <div v-prefix class="#image__content flex">
            <div v-prefix class="#image__image-sizer-container relative flex-item-1 py-2 px-6 flex flex-align-center flex-justify-center text-center">
                <div v-prefix class="#image__image-sizer relative w-100 h-100">
                    <img v-if="selected_image" v-lazy="selected_image_src" :alt="selected_image.caption" ref="image" class="w-100 h-100" />
                </div>

                <button v-prefix @click="prev_image" :disabled="disable_prev_button" type="button" class="#image__prev-btn" aria-label="View previous image in gallery">
                    <icon>sf-arrows-left-thin</icon>
                </button>

                <button v-prefix @click="next_image" :disabled="disable_next_button" type="button" class="#image__next-btn" aria-label="View previous image in gallery">
                    <icon>sf-arrows-right-thin</icon>
                </button>
            </div>

            <aside v-prefix class="#image__details border-light-l overflow-auto">
                <div class="text-center border-light-b pa-2">
                    <div v-prefix class="#ad">
                        <ad-spot name="galleries-image-view-1" />
                    </div>
                </div>

                <div v-if="selected_image" class="pa-2 border-light-b">
                    <h3 class="type-fancy type-base mt-0 mb-1 text-bold">Caption</h3>
                    
                    <div>{{ selected_image.caption || 'No caption available' }}</div>
                    
                    <div v-show="selected_image.credit" class="text-on-light type-small mt-1">Photo by {{ selected_image.credit }}</div>
                </div>

                <div v-if="selected_image" v-show="selected_image.players && selected_image.players.length" class="pa-2 border-light-b">                
                    <h3 class="type-fancy type-base mt-0 mb-2 text-bold">Featured Players</h3>

                    <ul class="reset">
                        <li 
                            v-for="(player, player_index) in selected_image.players" 
                            :key="player.id" 
                            :class="{
                                'mt-1': player_index > 0,   
                            }"
                            class="flex inline-flex flex-align-center"
                        >
                            <div class="mr-1 rounded overflow-hidden" style="width:36px;height:36px;">
                                <img :src="`${player.image_url}?width=36&height=36&mode=crop`" :alt="`${player.name}'s headshot`" />
                            </div>

                            <span>{{ player.name }}</span>
                        </li>
                    </ul>
                </div>

                <div v-if="gallery" v-show="gallery.tags && gallery.tags.length" class="pa-2 border-bottom-b">                
                    <h3 class="type-fancy type-base mt-0 mb-2 text-bold">Tags</h3>

                    <ul class="reset flex flex-wrap">
                        <li v-for="tag in gallery.tags" :key="tag">
                            <span class="sidearm-galleries-btn --primary --rounded mr-1 mb-1">{{ tag }}</span>
                        </li>
                    </ul>
                </div>

                <div v-if="template_options.image_download_enabled" class="pa-2">
                    <h3 class="type-fancy type-base mt-0 mb-2 text-bold">Download</h3>

                    <a v-prefix :href="selected_image.large" target="_blank" download="gallery_image" class="sidearm-galleries-btn --basic --inline-block text-left">
                        <icon class="mr-1">icon-download-alt</icon>
                        <span>Save Image</span>
                    </a>
                </div>
            </aside>
        </div>
    </div>
</template>

<script>
    import GalleryHeader from '@/components/GalleryHeader'

    import { mapState } from 'vuex'
    import api from '@/api'
    import { set_page_title } from '@/helpers'

    export default {
        components: {
            GalleryHeader
        },

        data: () => ({
            initializing: true,

            gallery: null,

            gallery_id: 0,

            image_id: 0,

            selected_image: null,

            image_transitioning_interval: null,
        }),

        computed: {
            ...mapState([
                'template_options',
            ]),

            selected_image_src() {
                if (this.template_options.watermark_enabled === true && this.template_options.watermark_text) {
                    return `${this.selected_image.large}?watermark=gal&nickname=${this.template_options.watermark_text}`;
                }
                
                return this.selected_image.large;
            },

            disable_prev_button() {
                if (!this.selected_image) {
                    return true;
                }

                return this.selected_image.index === 0;
            },

            disable_next_button() {
                if (!this.selected_image) {
                    return true;
                }

                return this.selected_image.index === this.gallery.images.length - 1;
            },

            header_component_info() {
                return {
                    id: this.$route.params.gallery_id,
                    title: this.gallery ? this.gallery.title : '',
                    title_slug: this.$route.params.gallery_slug,
                    sport_slug: this.$route.params.sport_slug,
                    total_images: this.gallery ? this.gallery.image_count : '',
                    current_image: this.selected_image ? this.selected_image.index + 1 : ''
                }
            },
        },

        watch: {
            $route(newVal, oldVal) {
                if (newVal.params.gallery_id != oldVal.params.gallery_id) {
                    this.gallery_id = newVal.params.gallery_id;
                }

                if (newVal.params.image_id != oldVal.params.image_id) {
                    this.image_id = newVal.params.image_id;
                }
            },

            'selected_image.id'(newVal, oldVal) {
                if (newVal == oldVal) {
                    return;
                }
                
                set_page_title(`${this.gallery.title} - Image ${this.selected_image.index + 1}: ${this.selected_image.caption}`);

                if (this.$refs.image) {
                    this.$refs.image.classList.add('is-transitioning');
                }

                this.image_transitioning_interval = setInterval(() => {
                    this.$refs.image.classList.remove('is-transitioning');
                }, 1500);

                this.$router.replace({ name: 'image', params: { 
                    gallery_slug: this.$route.params.gallery_slug,
                    gallery_id: this.gallery_id,
                    image_id: newVal,
                    image_caption_slug: this.selected_image.caption_slug.substring(0,30) || `image-${this.selected_image.index + 1}`,
                } })
            }
        },

        methods: {
            prev_image() {
                this.selected_image = this.gallery.images[this.selected_image.index - 1]
            },

            next_image() {
                this.selected_image = this.gallery.images[this.selected_image.index + 1]
            },
        },

        created() {
            this.gallery_id = this.$route.params.gallery_id;
            this.image_id = this.$route.params.image_id;

            api.get_gallery(this.gallery_id)
                .then(gallery => {
                    this.gallery = gallery;

                    this.selected_image = this.gallery.images.find(image => image.id === parseInt(this.image_id));

                    this.initializing = false;
                })
        },

        beforeDestroy() {
            if (this.image_transitioning_interval) {
                clearInterval(image_transitioning_interval);
                this.image_transitioning_interval = null;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__image {
        &__gallery-header-container {
            margin: 0 $spacer*-2;
            padding: 0 $spacer*2;
        }

        &__content {
            margin: 0 $spacer*-2;
            height: calc(100vh - 85px - #{$spacer*4} - max(3rem, calc(1.5rem + 2px + #{$spacer*2})));
        }

        &__image-sizer {
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: contain;
                object-position: center center;
            }
        }

        &__prev-btn, &__next-btn {
            @include base-reset();

            border: 1px solid $stroke-on-light;
            background-color: $color-white;
            color: $text-on-light;
            font-size: 1rem;
            height: 3rem;
            line-height: 3rem;
            opacity: 1;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            transition: .35s;
            width: 3rem;

            &:hover:not(:disabled) {
                background-color: $color-primary;
                color: $text-on-primary;
            }

            &:disabled {
                opacity: 0;
                pointer-events: none;
            }
        }

        &__prev-btn {
            left: -1px;
        }

        &__next-btn {
            right: -1px;
        }

        &__details {
            width: 300 + $spacer*4;
        }

        @media (max-width: #{$breakpoint-large-down}) {
            &__content {
                display: block;
                height: initial;
            }

            &__image-sizer-container {
                border-bottom: 1px solid $stroke-on-light;
                height: 56.25vw;
            }

            &__details {
                width: 100%;
            }
        }

        @media (max-width: #{$breakpoint-medium-down}) {
            &__content {
                margin: 0 $spacer*-1 !important;
            }

            &__image-sizer-container {
                height: 75vw;
                min-height: 360px;
                padding: 24px;
            }
        }
    }
</style>